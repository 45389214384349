import React, { useState, useEffect } from "react";
import { createContext } from "react";

const AuthContext = createContext(null);

function AuthProvider(props) {
  const [token, setToken] = useState(null);
  const [inputToken, setInputToken] = useState("");
  const [tokenAccess, setTokenAccess] = useState(false);

  useEffect(() => {
    setToken(process.env.REACT_APP_AUTH_TOKEN);
    const localStorageToken = localStorage?.getItem("token_access") || [];
    if (localStorageToken) {
      localStorageToken === token
        ? setTokenAccess(true)
        : setTokenAccess(false);
    }
  }, [token]);

  function handleTokenChange(event) {
    setInputToken(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (inputToken === token) {
      localStorage.setItem("token_access", inputToken);
      setTokenAccess(true);
    } else {
      alert("Token inválido! Peça ao seu superior o Token de acesso");
    }
  }

  return (
    <AuthContext.Provider value={token}>
      {tokenAccess ? (
        props.children
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{
            backgroundColor: "#004a6c",
            padding: "2rem",
            position: "fixed",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <input
              placeholder="Digite o token de acesso"
              type="text"
              onChange={handleTokenChange}
              className="sf-height-large-50"
            />
            <button
              className="sf-btn sf-btn-primary sf-mt-auto sf-height-large-50"
              type="submit"
            >
              Entrar
            </button>
          </div>
        </form>
      )}
    </AuthContext.Provider>
  );
}

function withAuth(Component) {
  return function AuthComponent(props) {
    return (
      <AuthContext.Consumer>
        {(token) => token && <Component {...props} />}
      </AuthContext.Consumer>
    );
  };
}

export { AuthProvider, withAuth };
