import React from "react";
import { Link } from "react-router-dom";
export function RenderComponents(props) {
  return (
    <div
      key={props.data.id}
      id="sf-content-cnpj"
      className="sf-row sf-mt-4 sf-mt-sm-0"
    >
      <div className="sf-col-12">
        <div className="sf-card">
          <div className="sf-card-header sf-border sf-border-bottom-0">
            <h3 className="sf-mb-0">{props.data.razao}</h3>
          </div>
          <div className="sf-card-body sf-border">
            <p className="sf-mb-2">
              <strong>CNPJ: {props.data.cnpj}</strong>
            </p>
            <p className="sf-m-0">
              Empresa de {props.data.endereco.municipio}/
              {props.data.endereco.uf} fundada em{" "}
              {props.data.atividade.dataInicioAtividade}.
            </p>
          </div>
        </div>
      </div>
      <div className="sf-col-12">
        <div className="sf-row">
          <div className="sf-col-12">
            <div className="sf-card">
              <div className="sf-card-header sf-border sf-border-bottom-0">
                <h3 className="sf-m-0">
                  <i className="mdi mdi-domain sf-mr-1 sf-text-info"></i>
                  Dados da empresa
                </h3>
              </div>
              <div className="sf-card-body sf-border">
                <ul className="sf-contact-business-content sf-display-flex sf-flex-wrap sf-justify-content-between">
                  <li className="sf-display-flex sf-align-items-center sf-justify-content-center sf-flex-wrap sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <i className="mdi mdi-phone sf-mb-2 sf-mr-1 sf-text-info"></i>
                    <p className="sf-mb-2 sf-text-center">Telefone</p>
                    {props.data.contato.ddd1 || props.data.contato.ddd2 ? (
                      <div className="sf-btn-block sf-text-center">
                        <Link
                          className="sf-btn-block sf-text-center"
                          to="#"
                          onClick={(e) => {
                            window.location.href = `tel:${props.data.contato.telefone1}`;
                            e.preventDefault();
                          }}
                        >
                          <span className="sf-display-block">
                            {props.data.contato.ddd1 &&
                              "(" + props.data.contato.ddd1 + ")"}{" "}
                            {props.data.contato.telefone1 &&
                              props.data.contato.telefone1}
                          </span>
                        </Link>
                        <Link
                          className="sf-btn-block sf-text-center"
                          to="#"
                          onClick={(e) => {
                            window.location.href = `tel:${props.data.contato.telefone2}`;
                            e.preventDefault();
                          }}
                        >
                          <span className="sf-display-block">
                            {props.data.contato.ddd2 &&
                              "(" + props.data.contato.ddd2 + ")"}{" "}
                            {props.data.contato.telefone2 &&
                              props.data.contato.telefone2}
                          </span>
                        </Link>
                      </div>
                    ) : (
                      <span className="sf-btn-block sf-text-info">
                        Não informado
                      </span>
                    )}
                  </li>
                  <li className="sf-display-flex sf-align-items-center sf-justify-content-center sf-flex-wrap             sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <i className="mdi mdi-email sf-mb-2 sf-mr-1 sf-text-info"></i>
                    <p className="sf-mb-2 sf-text-center">E-mail</p>
                    {props.data.contato.email ? (
                      <Link
                        className="sf-btn-block sf-text-center"
                        to="#"
                        onClick={(e) => {
                          window.location.href = `mailto:${props.data.contato.email}`;
                          e.preventDefault();
                        }}
                      >
                        {props.data.contato.email}
                      </Link>
                    ) : (
                      <span className="sf-btn-block sf-text-info">
                        Não informado
                      </span>
                    )}
                  </li>
                  <li className="sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <p>Situação Cadastral</p>
                    {props.data.atividade.situacaoCadastral}
                  </li>
                  <li className="sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <p>Tipo</p>
                    {props.data.atividade.identificadorMatriz}
                  </li>
                  <li className="sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <p>Capital Social</p>
                    {props.data.capitalSocial.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </li>
                  {/* <li className="sf-contact-content-list sf-border sf-border-radius sf-p-4 sf-text-center">
                    <p>Socios</p>
                    {props.data.socio.length}
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sf-col-12">
        <div className="sf-card">
          <div className="sf-card-header sf-border sf-border-bottom-0">
            <h3 className="sf-m-0">
              <i className="mdi mdi-file-account sf-mr-1 sf-text-info"></i>
              Cartão CNPJ
            </h3>
          </div>
          <div className="sf-card-body sf-border">
            <ul className="information-list-business">
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Número da inscrição</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.cnpj} - {props.data.atividade.identificadorMatriz}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Data de abertura</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.atividade.dataInicioAtividade}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Nome empresarial</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">{props.data.razao}</p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Nome Fantasia</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.nomeFantasia}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Porte</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">{props.data.porte}</p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">
                  Código e descirção da atividade econômica principal
                </h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.atividade.cnae}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">
                  Código das atividades econômicas secundárias
                </h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.cnaeSecundario}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <h5 className="sf-m-0 sf-px-3 sf-pt-3">Logradouro</h5>
                <p className="sf-m-0 sf-px-3 sf-pb-3">
                  {props.data.endereco.tipoLogradouro}{" "}
                  {props.data.endereco.logradouro}
                </p>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <div className="sf-row">
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">Número</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.numero}
                    </p>
                  </div>
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">Complemento</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.complemento}
                    </p>
                  </div>
                </div>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <div className="sf-row">
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">CEP</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.cep}
                    </p>
                  </div>
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">Bairro</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.bairro}
                    </p>
                  </div>
                </div>
              </li>
              <li className="information-list-business-li sf-border-radius">
                <div className="sf-row">
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">Municipio</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.municipio}
                    </p>
                  </div>
                  <div className="sf-col-12 sf-col-sm-2">
                    <h5 className="sf-m-0 sf-px-3 sf-pt-3">UF</h5>
                    <p className="sf-m-0 sf-px-3 sf-pb-3">
                      {props.data.endereco.uf}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {props.data?.socio?.length > 0 ? (
          <h3 className="sf-my-4">
            <i className="mdi mdi-account sf-mr-1 sf-text-info"></i>
            Socios
          </h3>
        ) : (
          ""
        )}
        {props.data?.socio?.map((dataSocio, i) => (
          <div className="sf-card" key={"_" + i}>
            <div className="sf-card-header sf-border sf-border-bottom-0">
              <h3 className="sf-mb-0">{dataSocio.nome}</h3>
            </div>
            <div className="sf-card-body sf-border">
              <ul className="information-list-business">
                <li className="information-list-business-li sf-border-radius">
                  <h5 className="sf-m-0 sf-px-3 sf-pt-3">Nome</h5>
                  <p className="sf-m-0 sf-px-3 sf-pb-3">
                    {dataSocio.qualificacaoRepresentante}
                  </p>
                </li>
                <li className="information-list-business-li sf-border-radius">
                  <h5 className="sf-m-0 sf-px-3 sf-pt-3">Qualificação</h5>
                  <p className="sf-m-0 sf-px-3 sf-pb-3">
                    {dataSocio.qualificacao}
                  </p>
                </li>
                <li className="information-list-business-li sf-border-radius">
                  <h5 className="sf-m-0 sf-px-3 sf-pt-3">Data Cadastral</h5>
                  <p className="sf-m-0 sf-px-3 sf-pb-3">
                    {dataSocio.dataEntrada}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ))}
        <div className="sf-card-footer sf-px-0 sf-border-0 sf-display-flex sf-justify-content-end">
          <button className="sf-btn sf-btn-primary">Enviar empresa</button>
        </div>
      </div>
    </div>
  );
}

export default RenderComponents;
