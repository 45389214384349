import React from "react";
import { Link } from "react-router-dom";

import spriteSVG from "../../img/svg/content-sprites.svg";
import imgProfile from "../../img/diego-example.jpeg";
import "../../scss/standard.scss";
import "../../scss/style.scss";
import "../../scss/menu.scss";

class Menu extends React.Component {
  menu() {
    if (window.innerWidth >= 768) {
      document.body.classList.toggle("menu-fechado");
      if (document.body.classList.contains("menu-fechado")) {
        localStorage.setItem("menu", "fechado");
      } else {
        localStorage.removeItem("menu");
      }
    } else {
      document.body.classList.toggle("menu-mobile-aberto");
    }
  }

  componentDidMount() {
    if (window.innerWidth >= 768) {
      if (localStorage.getItem("menu") === "fechado") {
        document.body.classList.add("menu-fechado");
      } else {
        document.body.classList.remove("menu-fechado");
      }
    }

    (function () {
      var current = window.location.pathname.split("")[0];
      if (current === "") return;
      var menuItems = document.querySelectorAll(".sf-menu-list-link");
      for (var i = 0, len = menuItems.length; i < len; i++) {
        if (menuItems[i].getAttribute("href").indexOf(current) !== -1) {
          menuItems[i].className += " active-nolink";
          menuItems[i].nextElementSibling.className += " active-nolink";
        }
      }
    })();
  }

  dropdown(menu) {
    var dropdowns = document.getElementsByClassName("sf-dropdown-menu");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
    document.querySelector(menu).classList.toggle("show");
  }

  mask() {
    document.body.classList.remove("menu-mobile-aberto");
  }

  render() {
    const dropDownInfo = {
      account: [
        {
          name: "Meu Perfil",
          icon: "mdi mdi-account-circle",
          url: "/dashboard",
        },
        { name: "Meu plano", icon: "mdi mdi-wallet", url: "/dashboard" },
        { name: "Suporte", icon: "mdi mdi-lifebuoy", url: "/dashboard" },
      ],
      conf: [
        {
          name: "Cadastro da Empresa",
          icon: "mdi mdi-office-building",
          url: "/",
        },
        {
          name: "Usuários e Permissões",
          icon: "mdi mdi-account-group",
          url: "/",
        },
        { name: "Configurações Adicionais", icon: "mdi mdi-cog", url: "/" },
      ],
    };
    const listRouter = [
      { name: "Dashboard", url: "", icon: "mdi mdi-view-dashboard" },
    ];
    window.onclick = function (event) {
      if (!event.target.matches(".sf-dropdown-action")) {
        var dropdowns = document.getElementsByClassName("sf-dropdown-menu");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    return (
      <>
        <div id="bar-header" className="bar-header">
          <div
            className="ham burger-link-mobile sf-display-flex sf-display-md-none"
            onClick={this.menu}
          >
            <svg width="24" height="24">
              <use xlinkHref={spriteSVG + `#menuHamb`} />
            </svg>
          </div>
          <p className="ola-usuario sf-display-none sf-display-md-flex sf-align-items-center">
            <i className="sf-text-muted mdi mdi-account sf-mr-2 sf-text-lg sf-dropdown-action"></i>
            <span className="sf-text-muted usuario">Diego Lima</span>
          </p>
          <div className="bloco-usuario">
            <div className="bloco-usuario">
              <ul className="lista-util-usuario">
                <li className="sf-dropdown lista-util-usuario-item">
                  <span
                    className="lista-util-usuario-link sf-dropdown-action"
                    onClick={() => this.dropdown("#dropdownconfiguration")}
                    title="Configurações"
                  >
                    <i className="mdi mdi-cog sf-dropdown-action"></i>
                  </span>
                  <div className="sf-dropdown-menu" id="dropdownconfiguration">
                    <div className="sf-dropdown-header">
                      <h6 className="sf-m-0 sf-text-uppercase">
                        Configurações
                      </h6>
                    </div>
                    {dropDownInfo.conf.map((list) => (
                      <Link
                        key={list.name}
                        to={list.url}
                        className="sf-dropdown-item"
                      >
                        <i className={list.icon}></i>
                        <span>{list.name}</span>
                      </Link>
                    ))}
                  </div>
                </li>
              </ul>
              <div className="sf-dropdown bloco-menu-usuario">
                <div
                  className="bloco-imagem-usuario sf-display-flex sf-align-items-center sf-dropdown-action"
                  onClick={() => this.dropdown("#dropdownuser")}
                >
                  <img
                    alt="Diego Lima"
                    src={imgProfile}
                    className="imagem-usuario sf-dropdown-action"
                  />
                </div>
                <div className="sf-dropdown-menu" id="dropdownuser">
                  <div className="sf-dropdown-header">
                    <h6 className="sf-m-0 sf-text-uppercase">Bem-vindo!</h6>
                  </div>
                  {dropDownInfo.account.map((list) => (
                    <Link
                      key={list.name}
                      to={list.url}
                      className="sf-dropdown-item"
                    >
                      <i className={list.icon}></i>
                      <span>{list.name}</span>
                    </Link>
                  ))}
                  <div className="sf-dropdown-divider"></div>
                  <Link to={"/"} className="sf-dropdown-item">
                    <i className="mdi mdi-logout"></i>
                    <span>Sair</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="sf-menu">
          <div className="sf-menu-header">
            <a className="sf-logo-link" href="/view.html">
              <svg className="logo">
                <use xlinkHref={spriteSVG + `#logotype`} fill="#d1d1d1" />
              </svg>
            </a>
            <div
              className="hamburger-link sf-display-none sf-display-md-block"
              onClick={this.menu}
            >
              <i
                className={`${
                  localStorage.getItem("menu") ? "mdi-menu" : "mdi-menu-open"
                } mdi sf-text-xlg sf-text-muted sf-dropdown-action`}
              ></i>
              {/* <svg width="24" height="24">
                <use xlinkHref={spriteSVG + `#menuHamb`} />
              </svg> */}
            </div>
          </div>
          <div className="sf-menu-body">
            <ul className="sf-menu-ul">
              {listRouter.map((liData) => (
                <li key={liData.name} className="sf-menu-li">
                  <Link to={`/` + liData.url} className="sf-menu-list-link">
                    <i className={liData.icon + " menu-list-icon"}></i>
                    <span className="sf-menu-list-text">{liData.name}</span>
                  </Link>
                  <div className="sf-menu-li-float">
                    <div className="sf-menu-li-float-content">
                      <Link
                        to={"/" + liData.url}
                        className="sf-menu-li-float-span"
                      >
                        {liData.name}
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <div className="mensagem-box"></div>
        <div className="ga-mascara-fundo" onClick={this.mask}></div>
      </>
    );
  }
}

export default Menu;
