import React from 'react';
import RoutesL from "./templates/Rotas/Routes";


function App() {
  return (
    <RoutesL />
  );
}

export default App;
