import React from "react";
import Menu from "./templates/Menu/Menu";
import ContentDash from "./templates/ConteudoDash/ContentDash";

function Dashboard() {
  return (
    <div>
      <Menu />
      <ContentDash />
    </div>
  );
}

export default Dashboard;
