function messageBox(status, mensagemText) {
  // cria um novo elemento div
  // e dá à ele conteúdo
  var divNova = document.createElement("div");
  var icon = document.createElement("i");
  var boxMensagem = document.querySelector(".mensagem-box");
  // adiciona o novo elemento criado e seu conteúdo ao DOM
  var conteudoNovo;
  if (status === "successRegister") {
    conteudoNovo = document.createTextNode(mensagemText);
    divNova.classList.add("success");
  }

  if (status === "success") {
    conteudoNovo = document.createTextNode(mensagemText);
    divNova.classList.add(
      "success",
      "sf-display-flex",
      "sf-align-items-center"
    );
    icon.classList.add("mdi", "mdi-information-outline", "sf-mr-2");
  } else if (status === "error") {
    conteudoNovo = document.createTextNode(mensagemText);
    divNova.classList.add("error", "sf-display-flex", "sf-align-items-center");
    icon.classList.add("mdi", "mdi-information-outline", "sf-mr-2");
  } else if (status === "serverError") {
    conteudoNovo = document.createTextNode(mensagemText);
  } else if (status === "warning") {
    conteudoNovo = document.createTextNode(mensagemText);
    divNova.classList.add(
      "warning",
      "sf-display-flex",
      "sf-align-items-center"
    );
    icon.classList.add("mdi", "mdi-information-outline", "sf-mr-2");
  }

  divNova.appendChild(icon);
  divNova.appendChild(conteudoNovo);
  if (!document.body.classList.contains("mensagem")) {
    document.body.appendChild(divNova);
  }
  divNova.classList.add("mensagem");
  boxMensagem.appendChild(divNova);
  setTimeout(function () {
    divNova.style.transition = "transform .15s linear";
    divNova.style.transform = "translateX(100%)";
    setTimeout(function () {
      divNova.remove();
    }, 300);
  }, 3000);
}

export { messageBox };
