import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderSegmentos from "../ListaEmpresas/ListaEmpresas";
import searchSvg from "../../img/search-svg.svg";
import { messageBox } from "../../functions/message";
import loadingImage from "../../img/svg/loading.svg";

import {
  getSegmentos,
  getCnae,
  getMunicipio,
} from "../../configuracaoRotasAPI/api";

function CnpjSegmentos() {
  const [jsonSegmentos, setJsonSegmentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorConsult, setErrorConsult] = useState("");
  const navigate = useNavigate();
  const router = useParams();
  const [objetoCnae, setObjetoCnae] = useState({
    segmentos: "",
    segmentosId: "",
    municipio: "",
    municipioId: "",
    dateInicial: "",
  });

  useEffect(() => {
    async function getapiCnae(url) {
      fetch(url)
        .then((res) => {
          switch (res.status) {
            case 200:
              return res.json();
            case 404:
              throw new Error("Cnae não encontrado.");
            default:
              throw new Error("Erro ao filtrar o Cnae");
          }
        })
        .then((data) => {
          var inputCnae = document.querySelector(".segments-input");
          var cnaeData = [];
          data.forEach((element) => {
            if (element.id.toString() === router.cnae) {
              setObjetoCnae((prevState) => ({
                ...prevState,
                segmentos: element.descricao
                  .trim()
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
                segmentosId: element.id,
              }));
            }
            cnaeData.push({ id: element.id, nome: element.descricao });
          });
          autocomplete(inputCnae, cnaeData);
        })
        .catch((error) => {
          messageBox("error", error);
        });
    }
    async function getapiMunicipio(url) {
      fetch(url)
        .then((res) => {
          switch (res.status) {
            case 200:
              return res.json();
            case 404:
              throw new Error("Município não encontrado.");
            default:
              throw new Error("Erro ao filtrar o Município");
          }
        })
        .then((data) => {
          var inputMunicipio = document.querySelector(".estates-input");
          var cnaeMunicipio = [];
          data.forEach((element) => {
            if (element.id.toString() === router.municipio) {
              document.querySelector("input[name=inputMunicipio]").value =
                element.nome
                  .trim()
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ) +
                " (" +
                element.uf +
                ")";
              document
                .querySelector("input[name=inputMunicipio]")
                .setAttribute("id", element.id);
              setObjetoCnae((prevState) => ({
                ...prevState,
                municipio: element.nome
                  .trim()
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
                municipioId: element.id,
              }));
            }
            cnaeMunicipio.push({
              id: element.id,
              nome: element.nome,
              uf: element.uf,
            });
          });

          autocomplete(inputMunicipio, cnaeMunicipio);
        })
        .catch((error) => {
          messageBox("error", error);
        });
    }
    getapiCnae(getCnae);
    getapiMunicipio(getMunicipio);
    if (
      router.cnae !== "" &&
      router.cnae !== undefined &&
      router.municipio !== "" &&
      router.municipio !== undefined
    ) {
      setLoading(true);
      fetch(getSegmentos(router.cnae, router.municipio, router.dataCnae))
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 404:
              throw new Error("Cnae não encontrado.");
            case 500:
              throw new Error("Erro ao filtrar o Cnae");
            default:
              setJsonSegmentos([]);
              setJsonSegmentos(res);
              setLoading(false);
              messageBox("success", "Busca finalizada");
              if (res.length <= 0) {
                setErrorConsult("Nenhum dado foi encontrado");
              } else {
                setErrorConsult("");
              }
              break;
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorConsult(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.cnae, router.municipio, router.dataCnae]);

  function handleSubmit(e) {
    e.preventDefault();
    var segmentosInput = document.querySelector("input[name=inputProfissoes]");
    var municipioInput = document.querySelector("input[name=inputMunicipio]");
    if (!objetoCnae.segmentosId && !objetoCnae.municipioId) {
      messageBox("error", "Preencha os campos corretamente");
      segmentosInput.classList.add("sf-border-danger", "sf-text-danger");
      municipioInput.classList.add("sf-border-danger", "sf-text-danger");
    } else if (!objetoCnae.municipioId) {
      municipioInput.classList.add("sf-border-danger", "sf-text-danger");
    } else if (!objetoCnae.segmentosId) {
      segmentosInput.classList.add("sf-border-danger", "sf-text-danger");
    } else {
      segmentosInput.classList.remove("sf-border-danger", "sf-text-danger");
      municipioInput.classList.remove("sf-border-danger", "sf-text-danger");
      navigate(
        `/consulta/segmentos/${objetoCnae.segmentosId}/${
          objetoCnae.municipioId
        }${objetoCnae.dateInicial ? `/${objetoCnae.dateInicial}` : ""}`
      );
    }
  }

  function autocomplete(inp, arr) {
    var currentFocus;
    if (inp && arr) {
      inp.addEventListener("input", function (e) {
        var a,
          i,
          val = replaceSpecialChars(this.value);
        closeAllLists();
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        if (!val) {
          this.parentNode.appendChild(a);
          var bc = document.createElement("div");
          bc.classList.add("sf-bg-light");
          bc.innerHTML = `<label class="sf-text-capitalize">Nenhum dado encontrado</label>`;
          a.appendChild(bc);
        } else {
          currentFocus = -1;
          this.parentNode.appendChild(a);
          for (i = 0; i < arr.length; i++) {
            const lowercase = replaceSpecialChars(arr[i].nome.toLowerCase());
            const match = lowercase.substr(lowercase.indexOf(val), val.length);
            if (match === val.toLowerCase()) {
              var bd = document.createElement("div");
              const uf = arr[i].uf ? " (" + arr[i].uf + ")" : "";
              const description =
                lowercase.substr(0, lowercase.indexOf(val)) +
                "<strong>" +
                lowercase.substr(lowercase.indexOf(val), val.length) +
                "</strong>" +
                lowercase.substr(
                  lowercase.indexOf(val) + val.length,
                  lowercase.length
                ) +
                uf;
              const name = lowercase + uf;
              const id = arr[i].id;

              const template = document.createElement("template");
              template.innerHTML = `<label class="sf-text-capitalize sf-cursor-pointer">${description}</label>
                            <input type='hidden' id='${id}' value='${name}' />`;
              bd.append(template.content);
              bd.addEventListener("click", function (e) {
                inp.setAttribute(
                  "id",
                  this.getElementsByTagName("input")[0].getAttribute("id")
                );
                inp.value = this.getElementsByTagName("input")[0].value;
                if (inp.classList.contains("segments-input")) {
                  setObjetoCnae((prevState) => ({
                    ...prevState,
                    segmentos: this.getElementsByTagName("input")[0].value,
                    segmentosId:
                      this.getElementsByTagName("input")[0].getAttribute("id"),
                  }));
                } else if (inp.classList.contains("estates-input")) {
                  setObjetoCnae((prevState) => ({
                    ...prevState,
                    municipio: this.getElementsByTagName("input")[0].value,
                    municipioId:
                      this.getElementsByTagName("input")[0].getAttribute("id"),
                  }));
                }
                closeAllLists();
              });
              a.appendChild(bd);
            }
          }
        }
      });
      inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode === 40) {
          currentFocus++;
          addActive(x);
        } else if (e.keyCode === 38) {
          currentFocus--;
          addActive(x);
        } else if (e.keyCode === 13) {
          e.preventDefault();
          if (currentFocus > -1) {
            if (x) x[currentFocus].click();
          }
        }
      });
      function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = x.length - 1;
        x[currentFocus].classList.add("autocomplete-active");
      }
      function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
      }
      function closeAllLists(elmnt) {
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt !== x[i] && elmnt !== inp) {
            x[i].parentNode.removeChild(x[i]);
          }
        }
      }

      document.addEventListener("click", function (e) {
        closeAllLists(e.target);
      });
    }
  }

  function replaceSpecialChars(str) {
    return str
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  return (
    <div>
      <div className="sf-row">
        <div className="sf-col-12 sf-mx-auto sf-form-group">
          <form onSubmit={handleSubmit} className="sf-row">
            <div className="sf-col-12 sf-col-md-12 sf-col-xl-12 sf-display-flex sf-justify-content-center">
              <div className="sf-row">
                <div className="sf-col-12 sf-col-md-4 sf-form-group">
                  <label className="sf-label">Profissões</label>
                  <input
                    name="inputProfissoes"
                    autoComplete="off"
                    value={objetoCnae.segmentos}
                    onChange={(e) =>
                      setObjetoCnae((prevState) => ({
                        ...prevState,
                        segmentos: e.target.value,
                      }))
                    }
                    className="segments-input filter-segments sf-text-capitalize sf-height-large-50"
                    placeholder="Ex. Software"
                  ></input>
                </div>
                <div className="sf-col-12 sf-col-md-3 sf-form-group">
                  <label className="sf-label">Municipio</label>
                  <input
                    name="inputMunicipio"
                    autoComplete="off"
                    disabled={objetoCnae.segmentos.length < 5}
                    onChange={(e) =>
                      setObjetoCnae((prevState) => ({
                        ...prevState,
                        municipio: e.target.value,
                      }))
                    }
                    className="estates-input filter-states sf-text-capitalize sf-height-large-50"
                    placeholder="Cidades"
                  ></input>
                </div>
                <div className="sf-col-12 sf-col-md-3 sf-form-group">
                  <label className="sf-label">Data Abertura</label>
                  <input
                    type="date"
                    disabled={
                      objetoCnae.municipio.length < 5 ||
                      objetoCnae.segmentos.length < 5
                    }
                    onChange={(e) =>
                      setObjetoCnae((prevState) => ({
                        ...prevState,
                        dateInicial: e.target.value,
                      }))
                    }
                    className="filter-states sf-text-capitalize sf-height-large-50"
                    placeholder="Data"
                  ></input>
                </div>
                <div className="sf-col-12 sf-col-md sf-form-group sf-display-flex">
                  <button
                    type="submit"
                    disabled={
                      objetoCnae.municipio.length < 1 ||
                      objetoCnae.segmentos.length < 5
                    }
                    className="sf-btn sf-btn-primary sf-mt-auto sf-btn-block sf-height-large-50"
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {jsonSegmentos.length > 0 && <RenderSegmentos data={jsonSegmentos} />}
      {jsonSegmentos.length <= 0 && !router.cnae && (
        <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
          <div className="sf-card sf-border-0 sf-boxshadow-none">
            <div className="sf-card-header sf-border-0 sf-boxshadow-none">
              <img
                className="sf-mx-auto sf-display-block"
                height="350px"
                alt="Segmentos"
                style={{ maxWidth: 90 + "%" }}
                src={searchSvg}
              />
            </div>
          </div>
        </div>
      )}
      {errorConsult.length > 0 && (
        <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
          <div className="sf-card sf-border-0 sf-boxshadow-none">
            <div className="sf-card-header sf-border-0 sf-boxshadow-none">
              <svg className="sf-error-svg sf-text-info" viewBox="0 0 1320 300">
                <symbol id="v-text-3">
                  <text textAnchor="middle" x="50%" y="30%" dy=".35em">
                    Atenção!
                  </text>
                </symbol>
                <symbol id="v-text-2">
                  <text textAnchor="middle" x="50%" y="60%" dy=".35em">
                    Nenhum dado foi encontrado.
                  </text>
                </symbol>
                <use xlinkHref="#v-text-2" className="text1"></use>
                <use xlinkHref="#v-text-3" className="text2"></use>
              </svg>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <>
          <div id="modal" className="sf-modal actived"></div>
          <div
            id="loading"
            className="sf-loading-animation sf-position-fixed sf-centerAbsolute-xy"
          >
            <img alt="loading" src={loadingImage} />
          </div>
        </>
      )}
    </div>
  );
}

export default CnpjSegmentos;
