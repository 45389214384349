import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderComponentsSocio from "../ListaEmpresas/ListaEmpresas";
import loadingImage from "../../img/svg/loading.svg";
import searchSvg from "../../img/search-svg.svg";
import { messageBox } from "../../functions/message";
import {
  getSocio,
  getEncurtadoCNPJ,
  getSocioAutocomplete,
} from "../../configuracaoRotasAPI/api";
import { NotificationNavigator } from "../NotificationNavigator";
import axios from "axios";

export function RenderSocio() {
  const [socio, setSocio] = useState("");
  const [socioResultReplaceCNPJ, setSocioResultReplaceCNPJ] = useState([]);
  const [errorConsult, setErrorConsult] = useState("");
  const [verify, setVerify] = useState(false);
  const [loadingSocio, setLoadingSocio] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [socioVerifica, setSocioVerifica] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const router = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (router.socio !== "" && router.socio !== undefined) {
      setSocio(router.socio);
      setLoadingSocio(true);
      Notification.requestPermission();
      axios
        .get(getSocio(router.socio))
        .then((json) => {
          if (json.status === 404) {
            messageBox("error", "Socio Inexistente");
            document
              .querySelector("input[name=socioInput]")
              .classList.add("sf-border-danger", "sf-text-danger");
            setVerify(false);
          } else {
            if (json.length > 0) {
              document
                .querySelector("input[name=socioInput]")
                .classList.remove("sf-border-danger", "sf-text-danger");
              document.querySelector("input[name=socioInput]").blur();
              messageBox("success", "Busca do socio finalizada");

              var cnpjSocio = json.map((obj) => obj.cnpjEmpresa).join(",");

              if (cnpjSocio) setVerify(false);
              axios
                .get(getEncurtadoCNPJ(cnpjSocio), {
                  headers: {
                    "Access-Control-Allow-Origin":
                      "https://consultacnpj.softensistemas.com.br",
                  },
                  mode: "cors",
                })
                .then((res) => res.json())
                .then((json) => {
                  setSocioResultReplaceCNPJ(json);
                  if (!json.length) {
                    setSocioVerifica(true);
                  } else {
                    setSocioVerifica(false);
                    setVerify(true);
                  }
                  if (Notification.permission === "granted") {
                    NotificationNavigator(
                      "Consulta CNPJ",
                      `A empresa requisitada foi renderizada com sucesso`
                    );
                  }
                  setLoadingSocio(false);
                  messageBox("success", "Busca das empresas finalizada");
                })
                .catch((error) => {
                  messageBox("error", error);
                  setErrorConsult(error);
                });
            } else {
              document
                .querySelector("input[name=socioInput]")
                .classList.add("sf-border-danger", "sf-text-danger");
              if (Notification.permission === "granted") {
                NotificationNavigator(
                  "Consulta CNPJ",
                  `A empresa requisitada não foi encontrada`
                );
              }
              messageBox("error", "Não foi possível encontrar a empresa");
              setLoadingSocio(false);
              setSocioVerifica(true);
              setSocio("");
              setVerify(false);
            }
          }
        })
        .catch((error) => {
          messageBox("error", error);
          setVerify(false);
          setLoadingSocio(false);
        });
    }
  }, [router.socio]);

  function socioAutoComplete(socioValue) {
    var inp = document.querySelector("input[name=socioInput]");
    if (socioValue.length >= 10) {
      consultAutoComplet(socioValue);
    } else if (socioValue.length < 10) {
      autocomplete(inp, []);
    }
  }

  function consultAutoComplet(socioValue) {
    var inp = document.querySelector("input[name=socioInput]");
    setInputLoading(true);
    axios
      .get(getSocioAutocomplete(socioValue))
      .then((json) => {
        if (json.status === 404) {
          messageBox("error", "Socio Autocomplete Inexistente");
        } else if (json.status === 406) {
          messageBox("error", "Digite pelo menos 5 caracteres");
        } else {
          if (json.data.length > 0) {
            messageBox("success", "Socio encontrado");
            setInputLoading(false);
          } else {
            messageBox("warning", "Nenhum socio encontrado");
            setInputLoading(false);
          }
          autocomplete(inp, json.data);
        }
      })
      .catch((error) => {
        messageBox("error", error);
      });
  }

  function autocomplete(inp, arr) {
    var currentFocus;
    var a,
      b,
      i,
      val = inp.value;
    closeAllLists();
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", `autocomplete-items sf-mx-0`);
    if (inp.value.length <= 4 || arr.length <= 0) {
      inp.parentNode.appendChild(a);
      var bc = document.createElement("div");
      bc.classList.add("sf-bg-light");
      bc.innerHTML = `<label class="sf-text-capitalize"> Nenhum dado encontrado </label>`;
      a.appendChild(bc);
    } else {
      currentFocus = -1;
      inp.parentNode.appendChild(a);
      for (i = 0; i < arr.length; i++) {
        const lowercase = arr[i].toLowerCase();
        const match = lowercase.substr(0, val.length);
        if (match === val.toLowerCase()) {
          b = document.createElement("div");

          const uf = arr[i].uf ? " (" + arr[i].uf + ")" : "";
          const description = lowercase.substr(val.length) + uf;
          const name = lowercase + uf;

          const template = document.createElement("template");
          template.innerHTML = `<label class="sf-text-capitalize sf-cursor-pointer"><strong>${match}</strong>${description}</label>
                            <input type='hidden'value='${name}' />`;
          b.append(template.content);
          b.addEventListener("click", function (e) {
            setSocio(this.getElementsByTagName("input")[0].value);
            inp.value = this.getElementsByTagName("input")[0].value;
            inp.setAttribute(
              "data-name",
              this.getElementsByTagName("input")[0].value
            );
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
    }
    inp.addEventListener("keydown", function (e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode === 40) {
        currentFocus++;
        addActive(x);
      } else if (e.keyCode === 38) {
        currentFocus--;
        addActive(x);
      } else if (e.keyCode === 13) {
        e.preventDefault();
        if (currentFocus > -1) {
          if (x) x[currentFocus].click();
        }
      }
    });
    function addActive(x) {
      if (!x) return false;
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      x[currentFocus]?.classList.add("autocomplete-active");
    }
    function removeActive(x) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt !== x[i] && elmnt !== inp) {
          x[i].parentNode.removeChild(x[i]);
        } else if (elmnt === "" && elmnt === null) {
        }
      }
    }

    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (
      e.target.socioInput.getAttribute("data-name") &&
      e.target.socioInput.getAttribute("data-name") === socio
    ) {
      navigate(`/consulta/socio/${socio}`);
    } else {
      messageBox("error", "Preencha o campo corretamente.");
      e.target.socioInput.classList.add("sf-border-danger");
    }
  }

  return (
    <div className="sf-row">
      <div className="sf-col-12 sf-mx-auto sf-position-relative">
        <div className="sf-row sf-form-group">
          <div className="sf-col-12 sf-mb-4 sf-col-md-12 sf-ml-12 sf-col-xl-5 sf-mx-auto sf-display-flex">
            <form onSubmit={handleSubmit} className="sf-row sf-form-100">
              <div className="sf-col">
                <label className="sf-label">
                  Socio{" "}
                  <small>
                    (digite pelo menos 10 caracteres para facilitar a consulta
                    do sócio)
                  </small>
                </label>
                <div className="sf-input-group">
                  <input
                    className={`${
                      inputLoading
                        ? "sf-border-right-0 sf-border-radius-right-0"
                        : ""
                    }
                      sf-text-capitalize sf-height-large-50`}
                    autoComplete="off"
                    name="socioInput"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Consultar Socio"
                  />
                  {inputLoading && (
                    <div className="sf-input-group-append">
                      <div className="sf-display-flex sf-align-items-center sf-justify-content-center sf-border sf-border-left-0 sf-border-radius sf-border-radius-left-0">
                        <img
                          className="sf-px-1 sf-loading-input"
                          alt="Loading"
                          src={loadingImage}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="sf-col-auto sf-display-flex">
                {!socio && (
                  <button
                    disabled={inputValue.length < 10}
                    className="sf-btn sf-btn-primary sf-mt-auto sf-height-large-50"
                    onClick={(e) => {
                      e.preventDefault();
                      socioAutoComplete(inputValue);
                    }}
                  >
                    Buscar
                  </button>
                )}
                {socio.length > 10 && (
                  <button
                    disabled={socio.length < 10}
                    className="sf-btn sf-btn-primary sf-mt-auto sf-height-large-50"
                    type="submit"
                  >
                    Consultar
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        {verify === true && router.socio && (
          <div className="sf-row">
            <div className="sf-col-12 sf-mx-auto">
              <RenderComponentsSocio data={socioResultReplaceCNPJ} />
            </div>
          </div>
        )}
        {socioVerifica && (
          <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
            <div className="sf-card sf-border-0 sf-boxshadow-none">
              <div className="sf-card-header sf-border-0 sf-boxshadow-none">
                <svg
                  className="sf-error-svg sf-text-info"
                  viewBox="0 0 1320 300"
                >
                  <symbol id="v-text-3">
                    <text textAnchor="middle" x="50%" y="30%" dy=".35em">
                      Atenção!
                    </text>
                  </symbol>
                  <symbol id="v-text-2">
                    <text textAnchor="middle" x="50%" y="60%" dy=".35em">
                      Nenhum dado foi encontrado.
                    </text>
                  </symbol>
                  <use xlinkHref="#v-text-2" className="text1"></use>
                  <use xlinkHref="#v-text-3" className="text2"></use>
                </svg>
              </div>
            </div>
          </div>
        )}
        {socioResultReplaceCNPJ.length <= 0 && !router.socio && (
          <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
            <div className="sf-card sf-border-0 sf-boxshadow-none">
              <div className="sf-card-header sf-border-0 sf-boxshadow-none">
                <img
                  className="sf-mx-auto sf-display-block"
                  height="350px"
                  style={{ maxWidth: 90 + "%" }}
                  alt="Socio"
                  src={searchSvg}
                />
              </div>
            </div>
          </div>
        )}
        {errorConsult.length > 0 && (
          <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
            <div className="sf-card sf-border-0 sf-boxshadow-none">
              <div className="sf-card-header sf-border-0 sf-boxshadow-none">
                <svg className="sf-error-svg" viewBox="0 0 1320 300">
                  <symbol id="v-text-3">
                    <text textAnchor="middle" x="50%" y="30%" dy=".35em">
                      Atenção!
                    </text>
                  </symbol>
                  <symbol id="v-text-2">
                    <text textAnchor="middle" x="50%" y="80%" dy=".35em">
                      {errorConsult === "Failed to fetch"
                        ? "Não foi possível obter os dados"
                        : errorConsult}
                    </text>
                  </symbol>
                  <use xlinkHref="#v-text-2" className="text1"></use>
                  <use xlinkHref="#v-text-3" className="text1"></use>
                </svg>
              </div>
            </div>
          </div>
        )}
        {loadingSocio && (
          <>
            <div id="modal" className="sf-modal actived"></div>
            <div
              id="loading"
              className="sf-loading-animation sf-position-fixed sf-centerAbsolute-xy"
            >
              <img alt="Loading" src={loadingImage} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RenderSocio;
