import React from "react";
import { Link } from "react-router-dom";
import errorImage from "../../img/error.svg";

function Home() {
  return (
    <div>
      <section className="sf-content-page sf-m-0">
        <img
          className="sf-mx-auto sf-display-block"
          src={errorImage}
          width="90%"
          alt="Error"
          style={{ maxHeight: 500 + "px" }}
        />
        <div className="sf-row">
          <div className="sf-col-12 sf-col-auto sf-mt-5 sf-mx-auto">
            <Link className="sf-btn sf-btn-secondary" to="/dashboard">
              Retornar a Dashboard
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
