import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RenderComponentsCnpj from "../TemplateCNPJ/templateCNPJ";
import loadingImage from "../../img/svg/loading.svg";
import { getTemplateCNPJ } from "../../configuracaoRotasAPI/api";
export function RenderSegmentosOutro(props) {
  const [segmentos, setSegmentos] = useState([]);
  const [cnpjResult, setCnpjResult] = useState([]);
  const [verifyResult, setVerifyResult] = useState(false);
  const [idClassName, setIdClassName] = useState("");
  const [loading, setLoading] = useState(false);

  const [filterOnchange, setFilterOnchange] = useState(false);
  const [countRenderEmpresas, setCountRenderEmpresas] = useState();

  const router = useParams();

  function autoCompleteList(inp, data) {
    inp.addEventListener("input", function (e) {
      setFilterOnchange(true);
      setCountRenderEmpresas(data.length);
      var val = replaceSpecialChars(this.value);
      data.forEach((arr) => {
        if (document.getElementById(arr.cnpj.replace(/[^\d]+/g, ""))) {
          var cnpjIdSelect = document.getElementById(
            arr.cnpj.replace(/[^\d]+/g, "")
          );
          cnpjIdSelect.style.display = "none";
          cnpjIdSelect.style.visibility = "hidden";
          var lowercase = arr.razaoSocial.toLowerCase();
          if (lowercase === "")
            lowercase = replaceSpecialChars("Nome não informado".toLowerCase());
          var match = lowercase.substr(lowercase.indexOf(val), val.length);
          if (match === val.toLowerCase()) {
            cnpjIdSelect.style.display = "block";
            cnpjIdSelect.style.visibility = "visible";
          }
        }
      });
      if (inp.value.length === 0) {
        setFilterOnchange(false);
        setCountRenderEmpresas(segmentos.length > 10 ? 10 : segmentos.length);
      }
    });
  }

  function replaceSpecialChars(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  useEffect(() => {
    setCountRenderEmpresas(segmentos.length > 10 ? 10 : segmentos.length);
    setSegmentos(props.data);
    if (props) {
      var inp = document.getElementById("business-filter");
      autoCompleteList(inp, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentos]);

  function resultCnpjView(e) {
    if (e.currentTarget.id !== "") {
      var routerData;
      if (idClassName && cnpjResult && router.dataCnae) {
        routerData = 34;
      } else if (idClassName && cnpjResult && router.socio) {
        routerData = 34;
      } else {
        routerData = 0;
      }
      e.currentTarget.parentNode.scrollTo({
        top:
          e.currentTarget.offsetTop - e.currentTarget.offsetHeight - routerData,
        behavior: "smooth",
      });
      setIdClassName(e.currentTarget.id);
      openSearchSegments(e.currentTarget.id);
    }
  }

  function openSearchSegments(cnpj) {
    setLoading(true);
    fetch(getTemplateCNPJ(cnpj))
      .then((res) => res.json())
      .then((json) => {
        if (json.status !== 404) {
          setCnpjResult(json);
          setVerifyResult(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setVerifyResult(false);
      });
  }

  function acaoRenderizarEmpresas(empresaAcao) {
    switch (empresaAcao) {
      case "show":
        if (countRenderEmpresas !== segmentos.length) {
          setCountRenderEmpresas(
            segmentos.length - countRenderEmpresas > 5
              ? countRenderEmpresas + 5
              : countRenderEmpresas + segmentos.length - countRenderEmpresas
          );
        }
        break;
      case "hide":
        if (countRenderEmpresas > 10) {
          setCountRenderEmpresas(
            countRenderEmpresas - 5 >= 10
              ? countRenderEmpresas - 5
              : countRenderEmpresas - (countRenderEmpresas - 10)
          );
        }
        break;
      case "hideAll":
        if (countRenderEmpresas > 10) {
          setCountRenderEmpresas(segmentos.length > 10 ? 10 : segmentos.length);
        }
        break;
      case "showAll":
        if (countRenderEmpresas < segmentos.length) {
          setCountRenderEmpresas(segmentos.length);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className="sf-row">
      {router.cnae || router.socio ? (
        <div className="sf-col-12 sf-position-relative">
          <div className="sf-row">
            <div
              className={
                verifyResult
                  ? "sf-col-12 sf-col-md-5 sf-col-lg-3 sf-contact-business-content sf-business-overflow"
                  : "sf-col-12 sf-contact-business-content sf-content-grid sf-business-overflow"
              }
              style={{ position: "sticky", top: "0" }}
            >
              <div className="sf-row">
                <div className="sf-col-12 sf-mb-3">
                  <div className="sf-row">
                    {cnpjResult && idClassName && (
                      <div className="sf-col-12 sf-mb-2">
                        <label
                          onClick={() => {
                            setIdClassName("");
                            setCnpjResult("");
                            setVerifyResult(false);
                          }}
                          className="sf-display-flex sf-align-items-center sf-cursor-pointer sf-font-weight-extra-bold"
                        >
                          <i className="mdi mdi-close sf-text-info sf-text-lg sf-mr-2"></i>
                          <span className="sf-text-info-underline-hover">
                            Fechar Empresas
                          </span>
                        </label>
                      </div>
                    )}
                    {router.dataCnae && (
                      <div className="sf-col-12 sf-mb-2 sf-display-flex sf-align-items-center sf-font-weight-bold">
                        <i className="mdi mdi-calendar sf-text-info sf-text-md sf-mr-2"></i>
                        A partir de:{" "}
                        <label className="sf-text-warning sf-ml-1">
                          {" "}
                          {router.dataCnae.split("-").reverse().join("/")}
                        </label>
                      </div>
                    )}
                    {router.socio && (
                      <div className="sf-col-12 sf-mb-2 sf-display-flex sf-align-items-center sf-font-weight-bold">
                        <i className="mdi mdi-account sf-text-info sf-text-md sf-mr-2"></i>
                        Sócio:{" "}
                        <label
                          title={router.socio}
                          className="sf-text-warning sf-text-capitalize sf-text-truncate sf-ml-1"
                        >
                          {" "}
                          <strong>{router.socio}</strong>
                        </label>
                      </div>
                    )}
                    <div
                      className={
                        verifyResult
                          ? "sf-col-12 sf-mb-3 sf-display-flex sf-align-items-center"
                          : "sf-col-12 sf-col-sm-6 sf-col-md-6 sf-col-lg-4 sf-col-xl-3 sf-mb-3 sf-display-flex sf-align-items-center"
                      }
                    >
                      <div className="sf-input-group">
                        <input
                          autoComplete="off"
                          id="business-filter"
                          className="sf-border-right-0 sf-border-radius-right-0"
                          type="text"
                          placeholder="Filtrar Empresa"
                        />
                        <div className="sf-input-group-append">
                          <div className="sf-display-flex sf-align-items-center sf-justify-content-center sf-border sf-border-left-0 sf-border-radius sf-border-radius-left-0">
                            <i className="mdi mdi-magnify sf-text-lg sf-px-3 sf-py-1"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <strong>
                    Foram encontradas{" "}
                    <label className="sf-text-info"> {segmentos.length} </label>
                    empresas
                  </strong>
                </div>
              </div>
              <ul className="sf-scroll-action sf-row">
                {segmentos
                  .slice(segmentos.length - countRenderEmpresas)
                  .map((list, i) => (
                    <li
                      title={list.razaoSocial}
                      onClick={(e) => resultCnpjView(e)}
                      key={i}
                      id={list.cnpj.replace(/[^\d]+/g, "")}
                      className={
                        idClassName === list.cnpj.replace(/[^\d]+/g, "")
                          ? "sf-col-12 cnpj-details sf-contact-content-list sf-mb-3 content-list-segments actived"
                          : "sf-col-12 cnpj-details sf-contact-content-list sf-mb-3 content-list-segments"
                      }
                    >
                      <div className="sf-p-3 sf-border-radius">
                        <span className="sf-mb-1 sf-display-block sf-text-truncate">
                          <strong>{list.razaoSocial}</strong>
                        </span>
                        <span className="sf-mb-1 sf-display-block sf-text-truncate">
                          {list.nomeFantasia
                            ? list.nomeFantasia
                            : "Nome não informado"}
                        </span>
                        <span className="sf-mb-1 sf-display-block">
                          {list.municipio}, {list.uf}
                        </span>
                        <span>{list.cnpj}</span>
                      </div>
                    </li>
                  ))}
              </ul>
              {!filterOnchange && (
                <div className="sf-row">
                  <div className="sf-col-12">
                    <p
                      className={`sf-font-weight-extra-bold sf-mb-0 ${
                        verifyResult ? "sf-mt-2" : "sf-mt-3"
                      }`}
                    >
                      <span className="sf-text-info">
                        (
                        {segmentos.length > 10
                          ? countRenderEmpresas
                          : segmentos.length}
                        )
                      </span>{" "}
                      Empresas de um total de{" "}
                      <span className="sf-text-info">({segmentos.length})</span>{" "}
                      renderizadas
                    </p>
                  </div>
                  <div
                    className={
                      verifyResult
                        ? "sf-col-6"
                        : "sf-col-6 sf-col-sm-3 sf-col-md-auto"
                    }
                  >
                    <button
                      disabled={segmentos.length === countRenderEmpresas}
                      title="Mostrar mais Empresas"
                      onClick={() => acaoRenderizarEmpresas("show")}
                      className="sf-btn sf-p-2 sf-btn-primary sf-mt-3 sf-mr-3 sf-display-flex sf-width-100 sf-justify-content-center sf-align-items-center"
                      type="button"
                    >
                      <i className="mdi mdi-plus sf-text-xl sf-mr-2"></i>
                      Empresas
                    </button>
                  </div>
                  <div
                    className={
                      verifyResult
                        ? "sf-col-6"
                        : "sf-col-6 sf-col-sm-3 sf-col-md-auto"
                    }
                  >
                    <button
                      disabled={
                        (countRenderEmpresas <= 10 &&
                          segmentos.length - countRenderEmpresas &&
                          countRenderEmpresas >= 10) ||
                        (segmentos.length === countRenderEmpresas &&
                          countRenderEmpresas <= 10)
                      }
                      title="Mostrar menos Empresas"
                      onClick={() => acaoRenderizarEmpresas("hide")}
                      className="sf-btn sf-p-2 sf-btn-primary sf-mt-3 sf-mr-3 sf-display-flex sf-width-100 sf-justify-content-center sf-align-items-center"
                      type="button"
                    >
                      <i className="mdi mdi-minus sf-text-xl sf-mr-2"></i>
                      Empresas
                    </button>
                  </div>
                  <div
                    className={
                      verifyResult
                        ? "sf-col-6"
                        : "sf-col-6 sf-col-sm-3 sf-col-md-auto"
                    }
                  >
                    <button
                      disabled={
                        (countRenderEmpresas <= 10 &&
                          segmentos.length - countRenderEmpresas &&
                          countRenderEmpresas >= 10) ||
                        (segmentos.length === countRenderEmpresas &&
                          countRenderEmpresas <= 10)
                      }
                      title="Esconder Empresas"
                      onClick={() => acaoRenderizarEmpresas("hideAll")}
                      className="sf-btn sf-btn-primary sf-mt-3 sf-mr-3 sf-display-flex sf-width-100 sf-justify-content-center sf-align-items-center"
                      type="button"
                    >
                      <i className="mdi mdi-eye-off-outline sf-text-xl sf-mr-2"></i>
                      Empresas
                    </button>
                  </div>
                  <div
                    className={
                      verifyResult
                        ? "sf-col-6"
                        : "sf-col-6 sf-col-sm-3 sf-col-md-auto"
                    }
                  >
                    <button
                      disabled={segmentos.length === countRenderEmpresas}
                      title="Mostrar todas Empresas"
                      onClick={() => acaoRenderizarEmpresas("showAll")}
                      className="sf-btn sf-btn-primary sf-mt-3 sf-display-flex sf-width-100 sf-justify-content-center sf-align-items-center"
                      type="button"
                    >
                      <i className="mdi mdi-eye-outline sf-text-xl sf-mr-2"></i>
                      Empresas
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="sf-col-12 sf-col-md-7 sf-col-lg-9 sf-mx-auto sf-mt-0 sf-pb-4 sf-pl-sm-4 sf-pt-0 sf-px-0 sf-pl-0">
              {verifyResult === true && (
                <RenderComponentsCnpj data={cnpjResult} />
              )}
            </div>
          </div>
          {loading && (
            <>
              <div id="modal" className="sf-modal actived"></div>
              <div
                id="loading"
                className="sf-loading-animation sf-position-fixed sf-centerAbsolute-xy"
              >
                <img alt="loading" src={loadingImage} />
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RenderSegmentosOutro;
