import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import Home from "../../Home";
import { AuthProvider } from "../AuthProvider";
import NotFound from "../NotFound/NotFound";

export function RoutesL() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="http://localhost:3000"
            element={<Navigate to="/" />}
          ></Route>
          <Route exact path="/dashboard" element={<Navigate to="/" />}></Route>
          <Route exact path="/cnpj/" element={<Navigate to="/" />}></Route>
          <Route exact path="/segmentos/" element={<Navigate to="/" />}></Route>

          <Route element={<Home />} path="" exact />
          <Route element={<Home />} path="/consulta/cnpj/:cnpj" />
          <Route element={<Home />} path="/consulta/socio/:socio/" />
          <Route element={<Home />} path="/consulta/socio/:socio/:dataSocio" />
          <Route
            element={<Home />}
            path="/consulta/segmentos/:cnae/:municipio"
          />
          <Route
            element={<Home />}
            path="/consulta/segmentos/:cnae/:municipio/:dataCnae"
          />

          <Route path="NotFound" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/NotFound" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default RoutesL;
