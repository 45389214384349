const HOST = process.env.REACT_APP_API;

export const getSegmentos = (cnae, municipio, dataCnae) =>
  `${HOST}/filter/list?page=0&cnae=${cnae}&municipio=${municipio}${
    dataCnae ? `&data=${dataCnae}` : ""
  }`;
export const getCnae = `${HOST}/filter/list/cnae`;
export const getMunicipio = `${HOST}/filter/list/municipio`;
export const getTemplateCNPJ = (cnpj) => `${HOST}/query/full/${cnpj}`;
export const getSocio = (socio) => `${HOST}/socio/list?nome=${socio}&page=0`;
export const getEncurtadoCNPJ = (cnpjEncurtado) =>
  `${HOST}/socio/list/cnpj?listcnpj=${cnpjEncurtado}`;
export const getSocioAutocomplete = (socioCompleted) =>
  `${HOST}/socio/autocomplete/socio?nome=${socioCompleted}&page=0`;
