import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import RenderCnpj from "../ConsultarCnpj/Consult";
import RenderSegments from "../ConsultarSegmentos/ConsultSegments";
import RenderSocio from "../ConsultarSocio/ConsultSocio";

import imagemEmpty from "../../img/welcome.svg";
import "../../scss/standard.scss";
import "../../scss/style.scss";
import "../../scss/menu.scss";

const ConsultTemplate = () => {
  // const navigate = useNavigate();
  const [consult, setConsult] = useState("");
  const router = useParams();

  function returnTemplate(params) {
    switch (params) {
      case "cnpj":
        return <RenderCnpj />;
      case "segments":
        return <RenderSegments />;
      case "partner":
        return <RenderSocio />;
      default:
        return (
          <div className="sf-row" id="list-business-consult">
            <div className="sf-col-6 sf-ml-auto sf-mr-auto sf-mb-4 sf-display-flex sf-justify-content-center">
              <img
                height="350px"
                style={{ maxWidth: 90 + "%" }}
                alt="empty"
                src={imagemEmpty}
              />
            </div>
          </div>
        );
    }
  }

  // function Redirect(e) {
  //     e.preventDefault();
  //     setConsult('')
  //     navigate('/')
  // }

  useEffect(() => {
    if (router.cnpj) {
      setConsult("cnpj");
    } else if (router.cnae) {
      setConsult("segments");
    } else if (router.socio) {
      setConsult("partner");
    } else {
      setConsult("");
    }
  }, [router.cnpj, router.cnae, router.socio]);

  return (
    <section className="sf-content-page">
      <div className="sf-row">
        <div className="sf-col-12 sf-col-xl-12 sf-mx-auto">
          {!router.cnpj && !router.cnae && !router.socio && (
            <div id="sf-title-content" className="sf-row">
              <div className="sf-col-sm-5 sf-m-auto">
                <h1
                  className={
                    router.cnpj || router.cnae || router.socio
                      ? "sf-h1-sm sf-text-left sf-transition-standard"
                      : "sf-h1-lg sf-text-center sf-transition-standard"
                  }
                >
                  Soften Leads
                </h1>
                <p
                  className={
                    router.cnpj || router.cnae || router.socio
                      ? "sf-p-sm sf-text-left sf-pb-2 sf-transition-standard"
                      : "sf-p-md sf-text-center sf-transition-standard"
                  }
                >
                  Encontre mais de 53 milhões de empresas.
                </p>
              </div>
            </div>
          )}
          <div className="sf-row">
            <div className="sf-col-12 sf-col-md-12 sf-col-xl-5 sf-ml-auto sf-mr-auto sf-mb-4">
              <ul className="sf-display-flex sf-flex-wrap sf-action-rendered-content sf-pt-2">
                <li className="sf-action-rendered-consult">
                  <span
                    onClick={() => setConsult("cnpj")}
                    className={`sf-display-block sf-text-center sf-mb-2 sf-border-bottom-2 sf-py-2 sf-px-3 btn-search-state ${
                      consult === "cnpj" && "actived"
                    }`}
                  >
                    CNPJ
                  </span>
                </li>
                <li className="sf-action-rendered-consult">
                  <span
                    onClick={() => setConsult("segments")}
                    className={`sf-display-block sf-text-center sf-mb-2 sf-border-bottom-2 sf-py-2 sf-px-3 btn-search-state ${
                      consult === "segments" && "actived"
                    }`}
                  >
                    Segmentos de mercado
                  </span>
                </li>
                <li className="sf-action-rendered-consult">
                  <span
                    onClick={() => setConsult("partner")}
                    className={`sf-display-block sf-text-center sf-mb-2 sf-border-bottom-2 sf-py-2 sf-px-3 btn-search-state ${
                      consult === "partner" && "actived"
                    }`}
                  >
                    Sócio
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {returnTemplate(consult)}
      <footer className="footer sf-text-center">
        <span>
          <span id="anoAtual">2023</span> © Soften Leads - 1.1 - Uma solução da{" "}
          <a
            href="https://www.softensistemas.com.br"
            target="_blank"
            rel="noreferrer"
          >
            Soften Sistemas
          </a>{" "}
          | Todos os direitos reservados
        </span>
      </footer>
    </section>
  );
};

export default ConsultTemplate;
