import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderComponents from "../TemplateCNPJ/templateCNPJ";
import searchSvg from "../../img/search-svg.svg";
import { messageBox } from "../../functions/message";
import loadingImage from "../../img/svg/loading.svg";
import { getTemplateCNPJ } from "../../configuracaoRotasAPI/api";

function Cnpj() {
  const [user, setUser] = useState([]);
  const [jsonCNPJ, setJsonCNPJ] = useState([]);
  const [cnpj, setCnpj] = useState("");
  const [verify, setVerify] = useState(false);
  const [verifyPesquisa, setVerifyPesquisa] = useState(false);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const router = useParams();

  useEffect(() => {
    setJsonCNPJ(JSON?.parse(localStorage.getItem("sugestoesCNPJ")));
    if (localStorage.getItem("sugestoesCNPJ") && !router.cnpj)
      setVerifyPesquisa(true);
    if (router.cnpj !== "" && router.cnpj !== undefined) {
      setCnpj(router.cnpj);
      setLoad(true);
      fetch(getTemplateCNPJ(router.cnpj))
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 404) {
            messageBox("error", "CNPJ Inexistente");
            document
              .querySelector("input[name=cnpjInput]")
              .classList.add("sf-border-danger", "sf-text-danger");
            setVerify(false);
          } else {
            document
              .querySelector("input[name=cnpjInput]")
              .classList.remove("sf-border-danger", "sf-text-danger");
            document.querySelector("input[name=cnpjInput]").blur();
            messageBox("success", "Busca finalizada");
            setUser(json);
            setVerify(true);
            setVerifyPesquisa(false);
            setLocalPesquisa(router.cnpj);
          }
          setLoad(false);
        })
        .catch((error) => {
          messageBox("error", error);
          setVerify(false);
          setLoad(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.cnpj]);

  function setLocalPesquisa(valorCNPJ) {
    var cnpjId;

    var sugestoesCNPJ = JSON.parse(
      localStorage.getItem("sugestoesCNPJ") || "[]"
    );
    sugestoesCNPJ.forEach(function (user, index) {
      if (user.cnpj === valorCNPJ) cnpjId = user.value;
      if (user.value >= 5 && !router.cnpj) {
        setVerifyPesquisa(true);
      }
    });

    let notesEdit = JSON.parse(
      localStorage.getItem("sugestoesCNPJ") || "[]"
    ).filter((item) => item.cnpj !== valorCNPJ);
    if (notesEdit) {
      notesEdit.push({
        cnpj: valorCNPJ,
        value: cnpjId ? (cnpjId < 5 ? cnpjId + 1 : cnpjId) : 1,
      });
      localStorage.setItem("sugestoesCNPJ", JSON.stringify(notesEdit));
    } else {
      sugestoesCNPJ.push({
        cnpj: valorCNPJ,
        value: cnpjId ? (cnpjId < 5 ? cnpjId + 1 : cnpjId) : 1,
      });
      localStorage.setItem("sugestoesCNPJ", JSON.stringify(sugestoesCNPJ));
    }
    setJsonCNPJ(JSON.parse(localStorage.getItem("sugestoesCNPJ") || "[]"));
  }

  function resetPesquisa() {
    localStorage.removeItem("sugestoesCNPJ");
    setVerifyPesquisa(false);
    setJsonCNPJ([]);
  }

  function redirectCnpj(cnpjRedirect) {
    if (cnpjRedirect) navigate(`/consulta/cnpj/${cnpjRedirect}`);
  }

  function cnpjSearch(params) {
    var x = params
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    params = !x[2]
      ? x[1]
      : x[1] + "." + x[2] + "." + x[3] + "/" + x[4] + (x[5] ? "-" + x[5] : "");
    return params;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!cnpj) {
      messageBox("error", "Preencha o campo.");
      e.target.cnpjInput.classList.add("sf-border-danger");
    } else {
      navigate(`/consulta/cnpj/${cnpj.replace(/[^\d]+/g, "")}`);
    }
  }

  return (
    <div className="sf-row">
      <div className="sf-col-12 sf-col-xl-12 sf-mx-auto">
        <div className="sf-row sf-form-group">
          <div className="sf-col-12 sf-mb-4 sf-col-md-12 sf-ml-12 sf-col-xl-5 sf-mx-auto ">
            <form onSubmit={handleSubmit} className="sf-row sf-form-100">
              <div className="sf-col">
                <label className="sf-label">CNPJ</label>
                <input
                  autoComplete="off"
                  name="cnpjInput"
                  className="sf-height-large-50"
                  value={cnpjSearch(cnpj)}
                  onChange={(e) => setCnpj(e.target.value)}
                  placeholder="Consultar CNPJ"
                />
              </div>
              <div className="sf-col-auto sf-display-flex">
                <button
                  disabled={cnpjSearch(cnpj).length < 18}
                  className="sf-btn sf-btn-primary sf-mt-auto sf-height-large-50"
                  type="submit"
                >
                  Consultar
                </button>
              </div>
            </form>
            {verifyPesquisa && (
              <div className="sf-row">
                <div className="sf-col-12 sf-mt-3 sf-display-flex sf-align-items-center">
                  <label className="sf-label sf-text-info sf-mb-0">
                    Seus CNPJ mais procurados
                  </label>
                  <label
                    onClick={() => resetPesquisa()}
                    className="sf-cursor-pointer sf-label sf-text-info sf-ml-auto sf-mb-0 sf-display-flex sf-align-items-center"
                  >
                    <i className="mdi mdi-close sf-text-lg sf-text-align-right"></i>
                    Limpar
                  </label>
                </div>
                {jsonCNPJ.reverse().map((cnpj, i) => (
                  <div key={i}>
                    {cnpj.value >= 5 && (
                      <div key={i} className="sf-col-12 sf-col-sm-auto">
                        <span
                          onClick={() => redirectCnpj(cnpj.cnpj)}
                          className="sf-btn-link sf-p-0 sf-text-info sf-font-weight-bold sf-cursor-pointer sf-display-block sf-mt-2"
                        >
                          {cnpj.cnpj}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {verify ? (
          <RenderComponents data={user} />
        ) : (
          <div className="sf-col-12 sf-col-md-8 sf-col-lg-8 sf-m-auto">
            <div className="sf-card sf-border-0 sf-boxshadow-none">
              <div className="sf-card-header sf-border-0 sf-boxshadow-none">
                <img
                  className="sf-mx-auto sf-display-block"
                  height="350px"
                  alt="Consulta CNPJ"
                  style={{ maxWidth: 90 + "%" }}
                  src={searchSvg}
                />
              </div>
            </div>
          </div>
        )}
        {load && (
          <>
            <div id="modal" className="sf-modal actived"></div>
            <div
              id="loading"
              className="sf-loading-animation sf-position-fixed sf-centerAbsolute-xy"
            >
              <img alt="loading" src={loadingImage} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Cnpj;
